import { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import en from "../assets/flags/en.svg";
import eu from "../assets/flags/eu.svg";
import de from "../assets/flags/de.svg";
import uk from "../assets/flags/uk.svg";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";
import { useOutsideClick } from "../hooks/useOutsideClick";

const flag = { en, uk, de, eu };
const languageMap = { eu: "en", uk: "uk", de: "de", en: "en" };
const languages = [{ title: "uk", to: paths.uk }, { title: "eu", to: paths.eu }, { title: "de", to: paths.de }];

export const LanguageButton = ({ className = "" }) => {
  const { currentLocale } = useTranslation();

  if (currentLocale === "en") {
    return;
  }

  const [isOpen, setIsOpen] = useState(null);

  const selectLanguage = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openDropdown = () => {
    setIsOpen(true);
  };

  const { pathname } = useLocation();

  const { ref } = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
      document.body.style.overflow = "";
    }
  });

  return (
    <div className="language-dropdown desktop">
      <button
        id="language-dropdown-btn"
        aria-haspopup="true"
        aria-expanded={isOpen}
        className={`button-text l ${isOpen ? "pointer-events-none" : ""}`}
        onClick={openDropdown}
      >
        <span
          id="selected-language"
          className="body-medium weight-500"
          data-lang={currentLocale}
        >
          <img
            src={flag[currentLocale]}
            alt={`${currentLocale} flag`}
            className={`icon-20 language-border-8 ${
              isOpen
                ? "language-outline-color-blue-light-5"
                : "language-outline-color-transparent pointer-events-none"
            }`}
          />{" "}
          {languageMap[currentLocale]}
        </span>
      </button>

      <ul
        ref={ref}
        id="language-dropdown-menu"
        role="menu"
        aria-labelledby="language-dropdown-btn"
        className={`dropdown-list shadow-7 ${className} ${
          isOpen ? "show" : ""
        }`}
      >
        {languages
          .filter((item) => item.title !== currentLocale)
          .map((item) => (
            <Link to={`${item.to}${pathname}`} key={item.to} role="menuitem">
              <li role="menuitem">
                <button
                  data-lang={item.title}
                  className={`dropdown-button ${
                    currentLocale === item.title ? "active" : ""
                  }`}
                  onClick={selectLanguage}
                >
                  <img
                    src={flag[item.title]}
                    alt={`${item.title} flag`}
                    className="icon-20"
                  />{" "}
                  <span className="body-medium text-secondary weight-500">
                    {languageMap[item.title]}
                  </span>
                </button>
              </li>
            </Link>
          ))}
      </ul>
    </div>
  );
};
