import { useMemo } from "react";

import { ReactComponent as Minus } from "../assets/minus.svg";

import { useCollapse } from "../hooks/useCollapse";
import { useTranslation } from "../hooks/useTranslation";

const FAQItem = ({ question, answer }) => {
  const { ref, collapsed, toggle } = useCollapse();
  const isExpanded = collapsed.value ? "active" : "";

  return (
    <div className="accordion faq-card" onClick={toggle}>
      <div className={`faq-question ${isExpanded}`}>
        {question}
        <div className="flex text-primary">
          <Minus className="icon-24" />
          <Minus className={`icon-24 absolute ${isExpanded}`} />
        </div>
      </div>
      <div
        ref={ref}
        style={{ maxHeight: collapsed.height }}
        className={`faq-answer collapsed-content ${isExpanded}`}
      >
        <p
          className="body-medium text-tertiary weight-400"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  );
};

const FAQAccordion = ({ faqs }) => {
  const { t } = useTranslation();

  return (
    <div className="section-faq">
      <div className="section">
        <h2 className="text-center text-secondary">{t.faq}</h2>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const FAQ = () => {
  const { t } = useTranslation();

  const faqConfig = useMemo(
    () => [
      { question: t.faqQuestion1, answer: t.faqAnswer1 },
      { question: t.faqQuestion2, answer: t.faqAnswer2 },
      { question: t.faqQuestion3, answer: t.faqAnswer3 },
      { question: t.faqQuestion4, answer: t.faqAnswer4 },
      { question: t.faqQuestion5, answer: t.faqAnswer5 },
      { question: t.faqQuestion6, answer: t.faqAnswer6 },
    ],
    [t]
  );

  return <FAQAccordion faqs={faqConfig} />;
};
