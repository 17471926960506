import { ReactComponent as XMarkIconGr } from "../../assets/x-mark-gr.svg";
import { ReactComponent as CheckSingle } from "../../assets/check-single.svg";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";

import { useCollapse } from "../../hooks/useCollapse";

export const Setting = ({
  title,
  description,
  isChecked,
  toggleChecked,
  isRequired = false,
}) => {
  const { collapsed, ref, toggle } = useCollapse();
  const isActive = collapsed.value ? "active" : "";
  const checked = isChecked ? "active" : "unchecked";
  const required = isRequired ? "disabled" : "";

  return (
    <>
      <div className="cookie-setting">
        <div
          onClick={toggle}
          className={`cookie-expand-item text-secondary ${isActive}`}
        >
          <ChevronDown className="icon-20 chvrn" />

          <p className="body-medium">{title}</p>
        </div>
        <div className={`cookie-switch ${required}`}>
          <div
            className={`switch ${checked} ${required}`}
            data-checked="monthly"
            onClick={toggleChecked}
          >
            <div className={`thumb icon-20 ${checked} ${required}`}>
              {isChecked ? (
                <CheckSingle className="icon-16" />
              ) : (
                <XMarkIconGr className="icon-16" />
              )}
            </div>
          </div>
        </div>
      </div>
      <p
        ref={ref}
        style={{ maxHeight: collapsed.height }}
        className={`cookie-text ${isActive}`}
      >
        {description}
      </p>
    </>
  );
};
