import { useEffect, useState } from "react";
import { Routes, Route, useResolvedPath } from "react-router-dom";

import "./App.css";
import "./index.css";
import "./medias.css";
import "./components/buttons/buttons.css";

import { useUpdateFavicon } from "./hooks/useFavicon";
import { useDocumentTitle } from "./hooks/useDocumentTitle";
import { useAnalyticsScript } from "./hooks/useAnalyticsScript";
import { useHtmlLangAttribute } from "./hooks/useLangAttribute";
import { useGlobalButtonFocus } from "./hooks/useGlobalButtonFocus";

import { paths } from "./constants/paths";

import { Home } from "./components/Home";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Page404 } from "./components/Page404";
import { Cookie } from "./components/cookie/Cookie";
import { PagePrices } from "./components/PagePrices";
import { PageContact } from "./components/PageContact";
import { PageCookies } from "./components/PageCookies";
import { AlertProvider } from "./components/alert/Alert";
import { PageImpressum } from "./components/PageImpressum";
import { PageAboutUs } from "./components/about-us/PageAboutUs";
import { PageIntegrationSlack } from "./components/PageIntegrationSlack";
import { PageLegalPrivacyPolicy } from "./components/PageLegalPrivacyPolicy";
import { PageLegalTermsOfService } from "./components/PageLegalTermsOfService";
import { PageProductVacancies } from "./components/products/PageProductVacancies";
import { PageProductHRPlatform } from "./components/products/PageProductHRPlatform";
import { PageProductATSDatabase } from "./components/products/PageProductATSDatabase";
import { PageIntegrationGoogleCalendar } from "./components/PageIntegrationGoogleCalendar";
import { PageLegalTermsOfServiceCandidate } from "./components/PageLegalTermsOfServiceCandidate";
import { PageProductRecruitingAsService } from "./components/products/PageProductRecruitingAsService";

export const App = () => {
  const { pathname } = useResolvedPath();
  const [active, setActive] = useState(paths.home);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useGlobalButtonFocus();
  useUpdateFavicon();
  useAnalyticsScript();
  useDocumentTitle();
  useHtmlLangAttribute();

  useEffect(() => {
    setActive(pathname);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  useEffect(() => {
    if (pathname === paths.cookies) {
      setIsModalOpen(true);
      document.body.style.overflow = "hidden";
    }
  }, [pathname]);

  return (
    <AlertProvider>
      <Header active={active} />

      <Routes>
        <Route path={paths.home} element={<Home />} />
        <Route path={paths.prices} element={<PagePrices />} />
        <Route path={paths.aboutUs} element={<PageAboutUs />} />
        <Route path={paths.contacts} element={<PageContact />} />
        <Route path={paths.cookies} element={<PageCookies />} />
        <Route path={paths.terms} element={<PageLegalTermsOfService />} />
        <Route path={paths.termsCandidate} element={<PageLegalTermsOfServiceCandidate />} />
        <Route path={paths.vacancies} element={<PageProductVacancies />} />
        <Route path={paths.privacy} element={<PageLegalPrivacyPolicy />} />
        <Route path={paths.hrPlatform} element={<PageProductHRPlatform />} />
        <Route path={paths.atsDatabase} element={<PageProductATSDatabase />} />
        <Route path={paths.integrationSlack} element={<PageIntegrationSlack />} />
        <Route path={paths.recruitingAsService} element={<PageProductRecruitingAsService />} />
        <Route path={paths.integrationGoogleCalendar} element={<PageIntegrationGoogleCalendar />} />

        {process.env.REACT_APP_PUBLIC_LOCALE === "de" && <Route path={paths.impressum} element={<PageImpressum />} />}

        <Route path={paths.notFound} element={<Page404 />} />
      </Routes>

      <Cookie isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Footer toggleCookie={setIsModalOpen} />
    </AlertProvider>
  );
};
