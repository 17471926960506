import { useCallback, useState } from "react";

import { Text } from "./form/Text";
import { TextArea } from "./form/Textarea";

import { useAlert } from "../hooks/useAlert";
import { getRandomEmoji } from "../utils/getRandomEmoji";
import { useTranslation } from "../hooks/useTranslation";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { useValidateField } from "../hooks/useValidateField";

import xMarkIconBl from "../assets/x-mark-bl.svg";

const host = window.location.host || window.location.hostname;

export const ModalContactUs = ({ open, toggleModal }) => {
  const { t } = useTranslation();
  const { validateField } = useValidateField();
  const { showAlert, ALERT_TYPES } = useAlert();
  const [formState, setFormState] = useState({
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    theme: { value: "", error: "" },
    request: { value: "", error: "" },
  });

  const handleChange = useCallback(
    (field, value) => {
      setFormState((prevState) => ({
        ...prevState,
        [field]: {
          value: value,
          error: "",
        },
      }));
    },
    [t]
  );

  const resetForm = () => {
    setFormState({
      name: { value: "", error: "" },
      email: { value: "", error: "" },
      theme: { value: "", error: "" },
      request: { value: "", error: "" },
    });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newFormState = { ...formState };

      let isValid = true;

      Object.keys(newFormState).forEach((key) => {
        if (key === "request") {
          return;
        }

        const error = validateField(key, newFormState[key].value);

        newFormState[key].error = error;

        if (error) {
          isValid = false;
        }
      });

      if (isValid) {
        try {
          const icon_emoji = getRandomEmoji();
          const formData = Object.keys(newFormState).reduce((acc, key) => {
            acc[key] = newFormState[key].value;

            return acc;
          }, {});
          const request =
            formData.request.length > 0 ? `\nRequest: ${formData.request}` : "";
          const text = `Name: ${formData.name}\nHost: ${host}\nEmail: ${formData.email}\nTheme: ${formData.theme}${request}`;
          const username = "Form: Contact us (prices)";
          const data = {
            text,
            username,
            icon_emoji,
            channel: "#site_inbox",
          };
          await fetch(
            "https://hooks.slack.com/services/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",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              mode: "no-cors",
              body: JSON.stringify(data),
            }
          );

          resetForm();
          toggleModal();
          showAlert(t.successfullySubmitted, ALERT_TYPES.SUCCESS);
        } catch (error) {
          console.error("Error:", error);
          setFormState(newFormState);
        }
      } else {
        setFormState(newFormState);
      }
    },
    [formState, t, host]
  );

  const { ref } = useOutsideClick(() => {
    if (open) {
      toggleModal();
      resetForm();
    }
  });

  const handleToggle = () => {
    toggleModal();
    resetForm();
  };

  return (
    <div className={`modal-bg ${open ? "expanded" : ""}`}>
      <div ref={ref} className="modal modal-prices">
        <div className="flex items-center justify-between">
          <h4 className="text-secondary">{t.createRequest}</h4>
          <button onClick={handleToggle} className="icon-button">
            <img src={xMarkIconBl} alt="close icon" className="icon-20" />
          </button>
        </div>
        <form noValidate onSubmit={handleSubmit} className="mt-24 h-full flex flex-col">
          <Text
            required
            type="text"
            name="name"
            label={t.nameLabel}
            placeholder={t.namePlaceholder}
            onChange={handleChange}
            value={formState.name.value}
            error={formState.name.error}
          />
          <Text
            required
            type="email"
            name="email"
            label={t.emailLabel}
            placeholder={t.emailPlaceholderEnter}
            containerClassName="mt-12"
            onChange={handleChange}
            value={formState.email.value}
            error={formState.email.error}
          />
          <Text
            required
            type="email"
            name="theme"
            label={t.themeLabel}
            placeholder={t.themePlaceholder}
            containerClassName="mt-12"
            onChange={handleChange}
            value={formState.theme.value}
            error={formState.theme.error}
          />
          <TextArea
            type="textarea"
            name="request"
            label={t.yourRequestLabel}
            placeholder={t.yourRequestPlaceholder}
            containerClassName="mt-12"
            onChange={handleChange}
            value={formState.request.value}
            error={formState.request.error}
          />
          <button
            type="submit"
            className="button contained-primary mt-24 w-full"
          >
            {t.sendMessage}
          </button>
        </form>
      </div>
    </div>
  );
};
