const loginEn = "https://marmend.com/users/log_in";
const loginOther = "https://marmend.eu/users/log_in";
const tryForFreeEn = "https://marmend.com/users/register";
const tryForFreeOther = "https://marmend.eu/users/register";

export const paths = {
  home: "/",
  prices: "/prices",
  contacts: "/contact",
  aboutUs: "/about-us",
  impressum: "/impressum",
  career: "https://app.marmend.eu/jobs",
  login: {
    en: loginEn,
    de: loginOther,
    eu: loginOther,
    uk: loginOther,
  },
  tryForFree: {
    en: tryForFreeEn,
    de: tryForFreeOther,
    eu: tryForFreeOther,
    uk: tryForFreeOther,
  },
  vacancies: "/products/vacancies",
  atsDatabase: "/products/ats-database",
  hrPlatform: "/products/hr-platform",
  recruitingAsService: "/products/recruiting-as-service",
  notFound: "*",

  integrationSlack: "/integrations/slack",
  integrationGoogleCalendar: "/integrations/google-calendar",

  cookies: "/legal/cookies",
  terms: "/legal/terms-of-service",
  termsCandidate: "/legal/candidate/terms-of-service",
  privacy: "/legal/privacy-policy",
  plausibleIO: "https://plausible.io/privacy-focused-web-analytics",

  de: "https://marmend.de",
  eu: "https://about.marmend.eu",
  en: "https://about.marmend.com",
  uk: "https://marmend.com.ua",

  facebook: "https://www.facebook.com/profile.php?id=61569398372214",
  xing: "#xing",
  linkedin: "https://www.linkedin.com/company/marmend/",
  instagram: {
    de: "https://www.instagram.com/marmend.de/",
    en: "https://www.instagram.com/marmend_com/",
    eu: "https://www.instagram.com/marmend_com/",
    uk: "https://www.instagram.com/marmend.com.ua/",
  },
  x: "https://x.com/Marmend_Company",
};
