export const HamburgerButton = ({ isOpen, toggleMenu }) => {
  return (
    <button
      className={`hamburger-button ${isOpen ? "open" : ""}`}
      onClick={toggleMenu}
      aria-label="Toggle Menu"
    >
      <div className="hamburger-lines">
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>
    </button>
  );
};
