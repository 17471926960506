import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { tryForFree } from "../utils/getPath";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useTranslation } from "../hooks/useTranslation";
import { useOutsideClick } from "../hooks/useOutsideClick";

import { Tasks } from "./Tasks";
import { Bother } from "./Bother";
import { Prices } from "./Prices";
import { Targets } from "./Targets";
import { Reviews } from "./Reviews";
import { Innovate } from "./Innovate";
import { HomeTicker } from "./Ticker";
import { Opportunities } from "./Opportunities";
import { LocalizedImage } from "./LocalizedImage";
import { IntegrationCards } from "./IntegrationCards";
import { ModalJoinForFree } from "./ModalJoinForFree";
import { ContainedButtonWithIcon } from "./buttons/ContainedButtonWithIcon";

const sections = [
  { id: "hero", threshold: 0.1 },
  { id: "innovate", threshold: 0.1 },
  { id: "bother", threshold: 0.5 },
  { id: "tasks", threshold: 0.7 },
  { id: "opportunities", threshold: 0.5 },
  { id: "mobile-ticker", threshold: 0.5 },
  { id: "prices-page", threshold: 0.3 },
  { id: "integrations", threshold: 0.6 },
  { id: "target", threshold: 0.4 },
  { id: "reviews", threshold: 0.3 },
];

export const Home = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    formType: { value: "charity", error: "" },
    nameOfTheFund: { value: "", error: "" },
    emailCharity: { value: "", error: "" },
    identificationCode: { value: "", error: "" },
    countryAndCityOfRegistration: { value: "", error: "" },
    descriptionOfActivities: { value: "", error: "" },
    companyName: { value: "", error: "" },
    emailStartup: { value: "", error: "" },
    yearOfEstablishment: { value: "", error: "" },
    startupDescription: { value: "", error: "" },
    privacy: { value: false, error: "" },
  });
  const sectionRefs = useRef([]);
  const resetForm = () => {
    setFormState({
      formType: { value: "charity", error: "" },
      nameOfTheFund: { value: "", error: "" },
      emailCharity: { value: "", error: "" },
      identificationCode: { value: "", error: "" },
      countryAndCityOfRegistration: { value: "", error: "" },
      descriptionOfActivities: { value: "", error: "" },
      companyName: { value: "", error: "" },
      emailStartup: { value: "", error: "" },
      yearOfEstablishment: { value: "", error: "" },
      startupDescription: { value: "", error: "" },
      privacy: { value: false, error: "" },
    });
  };
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "" : "hidden";
    resetForm();
  };

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, sections.length);
  }, []);

  const { ref } = useOutsideClick(() => {
    if (isModalOpen) {
      toggleModal();
      resetForm();
    }
  });

  const isLargeScreen = useMediaQuery("(min-width: 540px)");

  return (
    <div id="page-content-home">
      <ModalJoinForFree
        ref={ref}
        open={isModalOpen}
        formState={formState}
        setFormState={setFormState}
        toggleModal={toggleModal}
        resetForm={resetForm}
      />

      <div className="hero">
        <p className="text-hero weight-300">
          {t.talentSearch} <br/><span className="weight-700">{t.onThePlatform}</span>
        </p>
      </div>
      <div className="hero-buttons">
        <Link to={tryForFree("Company")}>
          <ContainedButtonWithIcon title={t.tryForFree} />
        </Link>
      </div>
      {isLargeScreen && (
        <div
          id="ticker"
          className="home-ticker-container before-gradient-left-to-right after-gradient-right-to-left"
        >
          <HomeTicker />
        </div>
      )}

      {sections.map((section) => {
        return (
          <div key={section.id} className="scroll-to-section">
            <div className={`section section-${section.id}`}>
              {section.id === "hero" && (
                <>
                  <div className="section relative">
                    <div className="blur-ball blur-size-460 blur-bg-1 blur-filter-112-5 top-n89-left"></div>
                    <div className="blur-ball blur-size-400 blur-bg-2 blur-filter-90 top-n53-right"></div>
                  </div>
                  <div className="section section-platform before-gradient">
                    <LocalizedImage
                      srcSet={{ default: "screen.jpg" }}
                      className="hero-image"
                    />
                  </div>
                </>
              )}
              {section.id === "innovate" && <Innovate />}
              {section.id === "bother" && <Bother />}
              {section.id === "tasks" && <Tasks />}
              {section.id === "opportunities" && <Opportunities />}
              {section.id === "mobile-ticker" && !isLargeScreen && (
                <HomeTicker />
              )}
              {section.id === "prices-page" && (
                <Prices variant="home" toggleModal={toggleModal} />
              )}
              {section.id === "integrations" && (
                <div className="section-integrations-content">
                  <div className="opportunities-description">
                    <h2
                      className="text-secondary section-integrations-text weight-300"
                      dangerouslySetInnerHTML={{ __html: t.favoriteServices }}
                    />
                    <p className="mt-12 text-tertiary body-medium max-w-510">
                      {t.integrationDescription}
                    </p>
                  </div>
                  <IntegrationCards isIntersecting />
                </div>
              )}
              {section.id === "target" && <Targets />}
              {section.id === "reviews" && <Reviews />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
