import { useEffect, useState } from "react";

import logo from "../assets/logo.svg";
import { useTranslation } from "../hooks/useTranslation";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useTasks } from "../hooks/useTasks";

import { ReactComponent as Sparkles } from "../assets/sparkles.svg";

export const Tasks = () => {
  const { t } = useTranslation();
  const { tasksList, currentLocale } = useTasks();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % tasksList.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const isLargeScreen = useMediaQuery("(max-width: 1200px)");

  const active = (index = -1) => {
    if (!isLargeScreen) {
      return activeIndex === index ? "active" : "";
    }

    return "active";
  };

  const displayedTasks = isLargeScreen ? tasksList.slice(0, -1) : tasksList;

  return (
    <>
      <div className="tasks-head">
        <img src={logo} alt="logo" />
        <p className="body-large weight-300">{t.facilitatesTheDailyTasks}</p>
      </div>
      <div className="tasks-images">
        <div className="blur-ball blur-bg-4 blur-size-312 blur-filter-90 absolute-center"></div>
        {displayedTasks.map((item, index) => (
          <div key={index} className={`i shadow-7 ${currentLocale} ${active(index)}`}>
            {item}
          </div>
        ))}
      </div>
      <div className="rounded-item">
        <Sparkles className="icon-24 text-primary" />
        <p>
          <span className="text-secondary body-medium">
            {t.weAreHereToMakeYourWork}
          </span>
          <span className="text-primary body-medium">
            {" "}
            {t.easierAndMoreEfficient}
          </span>
        </p>
      </div>
    </>
  );
};
