import { paths } from "../constants/paths";
import { fallbackLanguage } from "../constants/fallbackLanguage";

const publicLocale = process.env.REACT_APP_PUBLIC_LOCALE || fallbackLanguage;

export const legal = (path) => `${paths[publicLocale]}${path}`;

export const login = `${paths.login[publicLocale]}?origin=${publicLocale}`;

export const tryForFree = (type) => {
  const tryForFreePath = `${paths.tryForFree[publicLocale]}?origin=${publicLocale}`;

  if (type) {
    return tryForFreePath + `&type=${type}`;
  }

  return tryForFreePath;
};

export const instagram = paths.instagram[publicLocale];
