import { useState, useEffect } from "react";

export const useTicker = ({
  ref,
  margin = "10px",
  threshold = 0.4,
  repeat = false,
}) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const callback = (entries) => {
      const [entry] = entries;

      setIntersecting(entry.isIntersecting);

      if (!repeat && entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
      }
    };

    let observerRefValue = null;

    const observer = new IntersectionObserver(callback, { threshold });

    if (ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [ref, setIntersecting, margin, threshold, repeat]);

  return isIntersecting;
};
