import { LOCAL_STORAGE_COOKIES } from "../constants/cookiesLocalStorage";

const defaultSettings = {
  required: true,
  functional: false,
  analytics: false,
};

export const getStoredSettings = () => {
  try {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_COOKIES);

    if (storedSettings) {
      const parsedSettings = JSON.parse(storedSettings);

      return {
        ...defaultSettings,
        ...parsedSettings,
        required: true,
      };
    }
  } catch (error) {
    console.error("Error parsing stored cookie settings:", error);
  }

  return defaultSettings;
};
