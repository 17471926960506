import { useTranslation } from "./useTranslation";

const replaceNumber = (string, number) =>
  (string || "This field must be at least number characters long").replace(
    "number",
    number
  );

export const useValidateField = () => {
  const { t } = useTranslation();

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "email":
      case "emailCharity":
      case "emailStartup":
        if (!/\S+@\S+\.\S+/.test(value)) {
          error = t.validationEmail || "Please enter a valid email address";
        }

        break;

      case "privacy":
        if (!value) {
          error = t.validationPrivacy || "You must agree to the Privacy Policy";
        }

        break;

      case "name":
      case "theme":
      case "companyName":
      case "nameOfTheFund":
      case "identificationCode":
      case "yearOfEstablishment":
        if (value.trim().length < 3) {
          error = replaceNumber(t.validationLength, 3);
        }

        break;

      case "countryAndCityOfRegistration":
        if (value.trim().length < 5) {
          error =
            t.validationLocation || "Please enter a valid country and city";
        }

        break;

      case "request":
      case "descriptionOfActivities":
      case "startupDescription":
        if (value.trim().length < 10) {
          error = replaceNumber(t.validationLength, 10);
        }

        break;

      default:
        break;
    }

    return error;
  };

  return { validateField };
};
