import { useMemo } from "react";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslation } from "../../hooks/useTranslation";

import "./products.css";

import { Reviews } from "../Reviews";
import { ProductHead } from "./ProductHead";
import { ProductSectionList } from "./ProductSectionList";

export const PageProductATSDatabase = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  const list = useMemo(
    () => [
      {
        stepTitle: t.atsDatabaseStepTitle1Title,
        title: t.atsDatabaseStep1Title,
        description: t.atsDatabaseStep1Description,
        srcSet: {
          sm: "product-2-step-1-mobile.png",
          md: "product-2-step-1-tablet.png",
          default: "product-2-step-1-desktop.png",
        },
      },
      {
        stepTitle: t.atsDatabaseStepTitle2Title,
        title: t.atsDatabaseStep2Title,
        description: t.atsDatabaseStep2Description,
        srcSet: {
          sm: "product-2-step-2-mobile.png",
          md: "product-2-step-2-tablet.png",
          default: "product-2-step-2-desktop.png",
        },
      },
      {
        stepTitle: t.atsDatabaseStepTitle3Title,
        title: t.atsDatabaseStep3Title,
        description: t.atsDatabaseStep3Description,
        srcSet: {
          sm: "product-2-step-3-mobile.png",
          md: "product-2-step-3-tablet.png",
          default: "product-2-step-3-desktop.png",
        },
      },
      {
        stepTitle: t.atsDatabaseStepTitle4Title,
        title: t.atsDatabaseStep4Title,
        description: t.atsDatabaseStep4Description,
        srcSet: {
          sm: "product-2-step-4-mobile.png",
          md: "product-2-step-4-tablet.png",
          default: "product-2-step-4-desktop.png",
        },
      },
      {
        stepTitle: t.atsDatabaseStepTitle5Title,
        title: t.atsDatabaseStep5Title,
        description: t.atsDatabaseStep5Description,
        srcSet: {
          sm: "product-2-step-5-mobile.png",
          md: "product-2-step-5-tablet.png",
          default: "product-2-step-5-desktop.png",
        },
      },
      {
        stepTitle: t.atsDatabaseStepTitle6Title,
        title: t.atsDatabaseStep6Title,
        description: t.atsDatabaseStep6Description,
        srcSet: {
          sm: "product-2-step-6-mobile.png",
          md: "product-2-step-6-tablet.png",
          default: "product-2-step-6-desktop.png",
        },
      },
    ],
    [t]
  );

  const tags = useMemo(
    () => [t.product2tag1, t.product2tag2, t.product2tag3],
    [t]
  );

  return (
    <>
      <ProductHead
        title={t.productATSDatabaseTitle}
        subtitle={t.productATSDatabaseSubtitle}
        tags={tags}
        image={{
          src: "product-2.png",
          class: "max-w-1510 pt-40",
          blurClass: "blur-size-460 blur-bg-3 blur-filter-90 center-center",
          blur2Class: "blur-size-400 blur-bg-6 blur-filter-90 right-bottom z-1",
          blur3Class: "blur-size-500 blur-bg-7 blur-filter-70 right-bottom-3 -z-1",
          ...isSmallScreen ? { relative: "relative" } : [],
        }}
        activePath="atsDatabase"
      />

      <ProductSectionList list={list} />

      <Reviews />
    </>
  );
};
