import { useMemo } from "react";

import { Contact } from "./Contact";
import { IntegrationCard } from "./IntegrationCards";

import { useTranslation } from "../hooks/useTranslation";

import slackIcon from "../assets/slack.svg";
import logoMIcon from "../assets/logo-m.svg";
import unreadIcon from "../assets/unread.svg";
import ii1 from "../assets/images/en/integration-image-1.svg";
import ii2 from "../assets/images/en/integration-image-2.svg";
import ii3 from "../assets/images/en/integration-image-3.svg";
import ii4 from "../assets/images/en/integration-image-4.svg";
import ii5 from "../assets/images/en/integration-image-5.svg";
import ii6 from "../assets/images/en/integration-image-6.svg";

export const IntegrationSection = ({ children }) => {
  return <div className="integration-section">{children}</div>;
};

export const Note = ({ children }) => {
  return (
    <div className="integration-note text-secondary body-medium">
      <>{children}</>
    </div>
  );
};

const id1 = "enable-the-slack-integration";
const id2 = "access-your-slack-workspace";
const id3 = "manage-your-slack-notifications";
const id4 = "disconnect-the-slack-integration";

export const PageIntegrationSlack = () => {
  const { t } = useTranslation();

  const list = useMemo(
    () => [
      { icon: slackIcon, text: t.integration6 },
      { icon: logoMIcon, text: t.integration4 },
      { icon: unreadIcon, text: t.integration5 },
    ],
    [t]
  );

  return (
    <>
      <div className="section mt-40 mb-40 i-s">
        <div className="integration-slack-head">
          <div className="flex flex-col justify-center text-secondary">
            <h1>{t.slackIntegration}</h1>
            <p className="mt-12 body-medium">{t.allLevels}</p>
            <p className="mt-8 body-medium">{t.allPlans}</p>
          </div>

          <div className="relative w-half">
            <div className="blur-ball blur-size-326 blur-bg-2 blur-filter-90 right-center"></div>
            <div className="blur-ball blur-size-326 blur-bg-7 blur-filter-112-5 left-center-1 -z-1"></div>

            <div className="integration-slack-list">
              {list.map((item, index) => (
                <IntegrationCard
                  ignoreConfig
                  key={`integration-slack-card-${index}`}
                  icon={item.icon}
                  text={item.text}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="section-integration-slack">
          <div className="integration-side-left">
            <p className="text-primary body-small">{t.slack1}</p>
            <p className="mt-12 text-secondary body-medium">{t.slack2}</p>
            <IntegrationSection>
              <h4 id={id1} className="text-secondary">
                {t.slack3}
              </h4>
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack4,
                }}
              ></p>
              <img
                src={ii1}
                className="w-full rounded-12 shadow-7"
                alt="Slack integration"
              />
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack5,
                }}
              ></p>
              <img
                src={ii2}
                className="w-full rounded-12 shadow-7"
                alt="Slack connect"
              />
            </IntegrationSection>

            <IntegrationSection>
              <h4 id={id2} className="text-secondary">
                {t.slack6}
              </h4>
              <p className="text-secondary body-medium">{t.slack7}</p>
              <Note>
                <p
                  className="text-secondary body-medium"
                  dangerouslySetInnerHTML={{
                    __html: t.slack8,
                  }}
                ></p>
              </Note>
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack9,
                }}
              ></p>
              <img
                src={ii3}
                className="w-full rounded-12 shadow-7"
                alt="Slack allow"
              />
              <p className="text-secondary body-medium">{t.slack10}</p>
            </IntegrationSection>

            <IntegrationSection>
              <h4 id={id3} className="text-secondary">
                {t.slack11}
              </h4>
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack12,
                }}
              ></p>
              <img
                src={ii4}
                className="w-full rounded-12 shadow-7"
                alt="Slack notification"
              />
              <Note>
                <p
                  className="text-secondary body-medium"
                  dangerouslySetInnerHTML={{
                    __html: t.slack14,
                  }}
                ></p>
              </Note>
            </IntegrationSection>

            <IntegrationSection>
              <h4 id={id4} className="text-secondary">
                {t.slack15}
              </h4>
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack16,
                }}
              ></p>
              <img
                src={ii1}
                className="w-full rounded-12 shadow-7"
                alt="Slack integration"
              />
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack17,
                }}
              ></p>
              <img
                src={ii5}
                className="w-full rounded-12 shadow-7"
                alt="slack disconnect"
              />
              <p
                className="text-secondary body-medium"
                dangerouslySetInnerHTML={{
                  __html: t.slack18,
                }}
              ></p>
              <img
                src={ii6}
                className="w-full rounded-12 shadow-7"
                alt="Slack disconnect confirm modal"
              />
              <p className="text-secondary body-medium">{t.slack19}</p>
            </IntegrationSection>
          </div>

          <div className="integration-side-right">
            <div className="integration-navigation">
              <p className="text-primary body-large weight-600">{t.slack13}</p>
              <a href={`#${id1}`} className="a-integration">
                {t.slack3}
              </a>
              <a href={`#${id2}`} className="a-integration">
                {t.slack6}
              </a>
              <a href={`#${id3}`} className="a-integration">
                {t.slack11}
              </a>
              <a href={`#${id4}`} className="a-integration">
                {t.slack15}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
};
