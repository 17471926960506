import { useMemo } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "../hooks/useTranslation";
import { tryForFree } from "../utils/getPath";
import { ReviewCard } from "./ReviewCard";
import { ContainedButtonWithIcon } from "./buttons/ContainedButtonWithIcon";

export const Reviews = () => {
  const { t } = useTranslation();

  const list = useMemo(
    () => [
      {
        image: "avatar-recruiter.jpg",
        name: t.reviewRecruiterName,
        position: t.recruiter,
        email: t.reviewRecruiterEmail,
        location: t.reviewRecruiterLocation,
        quote: t.reviewQuote1,
      },
      {
        image: "avatar-hr-director.jpg",
        name: t.reviewHrDirectorName,
        position: t.hrDirector,
        email: t.reviewHrDirectorEmail,
        location: t.reviewHrDirectorLocation,
        quote: t.reviewQuote2,
      },
      {
        image: "avatar-project-manager.jpg",
        name: t.reviewProjectManagerName,
        position: t.projectManager,
        email: t.reviewProjectManagerEmail,
        location: t.reviewProjectManagerLocation,
        quote: t.reviewQuote3,
      },
    ],
    [t]
  );

  return (
    <div className="section-bother-pulse rvws relative">
      <Link to={tryForFree("Company")} className="z-1">
        <ContainedButtonWithIcon title={t.tryForFree} />
      </Link>

      <div className="absolute absolute-center">
        {list.map((item, index) => (
          <ReviewCard key={item.name || `review-${index}`} item={item} />
        ))}

        <div className="pulse-container">
          <div className="pulse"></div>
          <div className="pulse"></div>
          <div className="pulse"></div>
          <div className="pulse"></div>
          <div className="pulse"></div>
        </div>
      </div>
    </div>
  );
};
