import { Link } from "react-router-dom";

import { OutlinedButtonWithIcon } from "./buttons/OutlinedButtonWithIcon";

import { LocalizedImage } from "./LocalizedImage";

export const InnovateCard = ({ item }) => {
  return (
    <div className="innovate-list-card">
      <LocalizedImage srcSet={item.srcSet} />
      <h4 className="mt-16 text-secondary">{item.title}</h4>
      <p className="mt-8 text-tertiary body-medium">{item.description}</p>
      <Link to={item.to} className="block fit">
        <OutlinedButtonWithIcon className="mt-24" title={item.buttonTitle} />
      </Link>
    </div>
  );
};
