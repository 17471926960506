import { useState, useEffect } from "react";

import { retryImport } from "../utils/retryImport";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { fallbackLanguage } from "../constants/fallbackLanguage";

const currentLocale = process.env.REACT_APP_PUBLIC_LOCALE || fallbackLanguage;

const breakpoints = {
  default: "(min-width: 0px)", // all dimensions
  xs: "(max-width: 375px)", // mobile
  sm: "(max-width: 540px)", // mobile large
  md: "(max-width: 768px)", // tablet
};

export const LocalizedImage = ({ className, srcSet }) => {
  const [image, setImage] = useState({ src: null, error: false });
  const [isLoading, setIsLoading] = useState(true);
  const [activeBreakpoint, setActiveBreakpoint] = useState("default");

  const isXs = useMediaQuery(breakpoints.xs);
  const isSm = useMediaQuery(breakpoints.sm);
  const isMd = useMediaQuery(breakpoints.md);

  useEffect(() => {
    if (isXs && srcSet.xs) {
      setActiveBreakpoint("xs");
    } else if (isSm && srcSet.sm) {
      setActiveBreakpoint("sm");
    } else if (isMd && srcSet.md) {
      setActiveBreakpoint("md");
    } else {
      setActiveBreakpoint("default");
    }
  }, [isXs, isSm, isMd, srcSet]);

  const src = srcSet[activeBreakpoint] || srcSet.default;

  useEffect(() => {
    const loadImage = async () => {
      setIsLoading(true);

      try {
        const importFn = () => import(`../assets/images/${currentLocale}/${src}`);
        const imageModule = await retryImport(importFn);

        setImage({ src: imageModule.default, error: false });
      } catch (error) {
        console.error("Failed to load localized image:", error);

        try {
          const fallbackImportFn = () => import(`../assets/images/en/${src}`);
          const fallbackModule = await retryImport(fallbackImportFn);

          setImage({ src: fallbackModule.default, error: true });
        } catch (fallbackError) {
          console.error("Failed to load fallback image:", fallbackError);

          setImage({ src: null, error: true });
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadImage();
  }, [src, currentLocale]);

  const skeleton = (
    <>
      <style>
        {`
          @keyframes skeleton-pulse {
            0% { opacity: 0.1 }
            50% { opacity: 0.3 }
            100% { opacity: 0.1 }
          }
  
          .image-skeleton {
            min-width: 20px;
            border-radius: 8px;
          }
        `}
      </style>

      <img
        className={`${className} image-skeleton`}
        style={{
          backgroundColor: "var(--blue-light-5)",
          animation: "skeleton-pulse 1.5s ease-in-out 0.5s infinite",
        }}
      />
    </>
  );

  if (isLoading) {
    return skeleton;
  }

  if (image.src === null) {
    console.log("image.src === null");

    return skeleton;
  }

  return (
    <img
      src={image.src}
      alt={image.error ? `fallback ${src}` : src}
      className={className}
      onError={() => setImage((prev) => ({ ...prev, error: true }))}
    />
  );
};
