import { useMemo } from "react";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslation } from "../../hooks/useTranslation";

import "./products.css";

import { Reviews } from "../Reviews";
import { ProductHead } from "./ProductHead";
import { ProductSectionList } from "./ProductSectionList";

export const PageProductVacancies = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  const list = useMemo(
    () => [
      {
        stepTitle: `${t.step} 1`,
        title: t.vacanciesStep1Title,
        description: t.vacanciesStep1Description,
        srcSet: {
          sm: "product-1-step-1-mobile.png",
          md: "product-1-step-1-tablet.png",
          default: "product-1-step-1-desktop.png",
        },
      },
      {
        stepTitle: `${t.step} 2`,
        title: t.vacanciesStep2Title,
        description: t.vacanciesStep2Description,
        srcSet: {
          sm: "product-1-step-2-mobile.png",
          md: "product-1-step-2-tablet.png",
          default: "product-1-step-2-desktop.png",
        },
      },
      {
        stepTitle: `${t.step} 3`,
        title: t.vacanciesStep3Title,
        description: t.vacanciesStep3Description,
        srcSet: {
          xs: "product-1-step-3-mobile.png",
          md: "product-1-step-3-tablet.png",
          default: "product-1-step-3-desktop.png",
        },
      },
      {
        stepTitle: `${t.step} 4`,
        title: t.vacanciesStep4Title,
        description: t.vacanciesStep4Description,
        srcSet: {
          sm: "product-1-step-4-mobile.png",
          md: "product-1-step-4-tablet.png",
          default: "product-1-step-4-desktop.png",
        },
      },
      {
        stepTitle: `${t.step} 5`,
        title: t.vacanciesStep5Title,
        description: t.vacanciesStep5Description,
        srcSet: {
          sm: "product-1-step-5-mobile.png",
          md: "product-1-step-5-tablet.png",
          default: "product-1-step-5-desktop.png",
        },
      },
    ],
    [t]
  );

  const tags = useMemo(
    () => [t.product1tag1, t.product1tag2, t.product1tag3],
    [t]
  );

  return (
    <>
      <ProductHead
        title={t.productVacanciesTitle}
        subtitle={t.productVacanciesSubtitle}
        tags={tags}
        image={{
          src: "product-1.png",
          class: "max-w-810 pt-40",
          blurClass: "blur-size-400 blur-bg-3 blur-filter-90 top-right-2",
          blur2Class: "blur-size-400 blur-bg-7 blur-filter-70 top-right-2 -z-1",
          ...isSmallScreen ? { relative: "relative" } : [],
        }}
        activePath="vacancies"
      />

      <ProductSectionList list={list} />

      <Reviews />
    </>
  );
};
