import { forwardRef, useCallback } from "react";

import { Setting } from "./Setting";
import { useTranslation } from "../../hooks/useTranslation";
import xMarkIconBl from "../../assets/x-mark-bl.svg";

export const ModalSettings = forwardRef(
  ({ open, closeModal, settings, setSettings, onSubmit, onSubmitAll }, ref) => {
    const { t } = useTranslation();

    const toggleChecked = useCallback((key) => {
      if (key === "required") {
        return;
      }

      setSettings((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    }, []);

    return (
      <div className={`modal-bg m-cookies ${open ? "expanded" : ""}`}>
        <div ref={ref} className="modal modal-cookies">
          <div className="flex items-center justify-between">
            <h4 className="text-secondary">{t.confirmationOfStatus}</h4>
            <button onClick={closeModal} className="icon-button">
              <img src={xMarkIconBl} alt="close icon" className="icon-20" />
            </button>
          </div>

          <p className="mt-20 body-medium text-tertiary max-w-510">
            {t.privacyStatement}
          </p>

          <div className="mt-24 mobile-mb-24">
            <Setting
              isRequired
              title={t.mandatoryCookies}
              description={t.mandatoryCookiesDescription}
              isChecked={settings.required}
              toggleChecked={() => toggleChecked("required")}
            />
            <div className="setting-divider" />
            <Setting
              title={t.functionalCookies}
              description={t.functionalCookiesDescription}
              isChecked={settings.functional}
              toggleChecked={() => toggleChecked("functional")}
            />
            <div className="setting-divider" />
            <Setting
              title={t.analyticalCookies}
              description={t.analyticalCookiesDescription}
              isChecked={settings.analytics}
              toggleChecked={() => toggleChecked("analytics")}
            />
          </div>

          <div className="settings-actions">
            <button
              className="button outlined-primary w-full"
              onClick={onSubmit}
            >
              {t.savePreferences}
            </button>
            <button
              className="button contained-primary w-full"
              onClick={onSubmitAll}
            >
              {t.acceptAll}
            </button>
          </div>
        </div>
      </div>
    );
  }
);
