export const OutlinedButtonWithIcon = ({ title, className = "", onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button outlined-button-with-icon ${className}`}
    >
      <span className="body-medium weight-500">{title}</span>
      <div className="outlined-button-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5.5 3L10.5 8L5.5 13"
            className="stroke-outlined"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </button>
  );
};
