import { useMemo } from "react";
import { Link } from "react-router-dom";

import { UnorderedList } from "./ListUnordered";
import { OrderedList } from "./ListOrdered";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";

export const PageLegalPrivacyPolicy = () => {
  const { t } = useTranslation();

  const l1 = useMemo(
    () => [
      t.legal5,
      t.legal6,
      t.legal7,
      t.legal8,
      t.legal9,
      t.legal10,
      t.legal11,
      t.legal12,
      t.legal13,
      t.legal14,
    ],
    [t]
  );
  const l2 = useMemo(() => [t.legal33, t.legal34, t.legal35, t.legal36], [t]);
  const l3 = useMemo(() => [t.legal39, t.legal40, t.legal41], [t]);
  const l4 = useMemo(
    () => [t.legal46, t.legal47, t.legal48, t.legal49, t.legal50, t.legal51],
    [t]
  );
  const l5 = useMemo(
    () => [t.legal60, t.legal61, t.legal62, t.legal63, t.legal64],
    [t]
  );
  const l6 = useMemo(
    () => [t.legal68, t.legal69, t.legal70, t.legal71, t.legal72],
    [t]
  );
  const l7 = useMemo(() => [t.legal74, t.legal75, t.legal76], [t]);

  return (
    <div className="section section-terms text-secondary">
      <div className="max-w-700">
        <h1 className="text-uppercase">{t.legal1}</h1>
        <h4 className="mt-28 text-uppercase">{t.legal2}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal3 }}
        ></p>
        <h4 className="mt-28 text-uppercase">{t.legal4}</h4>
        <UnorderedList list={l1} className="mt-16 ul-terms-spacing" />
        <h4 className="mt-28 text-uppercase">{t.legal15}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal16 }}
        ></p>
        <h4 className="mt-28 text-uppercase">{t.legal17}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal18 }}
        ></p>
        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal19}
        </p>
        <UnorderedList list={[t.legal20]} className="mt-8 ul-terms-spacing" />
        <p className="mt-16 body-small weight-600">{t.legal21}</p>
        <UnorderedList list={[t.legal22]} className="mt-8 ul-terms-spacing" />
        <p className="mt-16 body-small weight-600">{t.legal23}</p>
        <UnorderedList list={[t.legal24]} className="mt-8 ul-terms-spacing" />
        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal25}
        </p>
        <UnorderedList list={[t.legal26]} className="mt-8 ul-terms-spacing" />
        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal27}
        </p>
        <UnorderedList list={[t.legal28]} className="mt-8 ul-terms-spacing" />
        <h4 className="mt-28 text-uppercase">{t.legal29}</h4>
        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal30}
        </p>
        <p
          className="mt-8 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal31 }}
        ></p>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal32 }}
        ></p>
        <OrderedList list={l2} className="mt-8 ul-terms-spacing" />
        <div className="mt-16 terms-divider"></div>
        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal37}
        </p>
        <p
          className="mt-8 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal38 }}
        ></p>
        <UnorderedList list={l3} className="mt-8 ul-terms-spacing" />

        <p
          className="mt-12 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal42 }}
        ></p>

        <h4 className="mt-28 text-uppercase">{t.legal43}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal44 }}
        ></p>
        <p
          className="mt-12 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal45 }}
        ></p>

        <OrderedList className="mt-8 ul-terms-spacing" list={l4} />

        <p className="mt-12 body-small">
          {t.legal52}
          <br />
          <Link
            target="_blank"
            to={paths.plausibleIO}
            className="text-primary a"
          >
            {paths.plausibleIO}
          </Link>
          .
        </p>

        <h4 className="mt-28 text-uppercase">{t.legal53}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal54 }}
        ></p>
        <div className="mt-16 terms-divider"></div>
        <h4 className="mt-28 text-uppercase">{t.legal55}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal56 }}
        ></p>

        <h4 className="mt-28 text-uppercase">{t.legal57}</h4>

        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal58 }}
        ></p>
        <UnorderedList list={[t.legal59]} className="mt-8 ul-terms-spacing" />

        <OrderedList className="mt-8 ul-terms-spacing-50" list={l5} />

        <UnorderedList list={[t.legal65]} className="mt-8 ul-terms-spacing" />

        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal66}
        </p>
        <UnorderedList list={[t.legal67]} className="mt-8 ul-terms-spacing" />

        <OrderedList className="mt-8 ul-terms-spacing-50" list={l6} />

        <UnorderedList list={[t.legal73]} className="mt-8 ul-terms-spacing" />

        <OrderedList className="mt-8 ul-terms-spacing-50" list={l7} />

        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal77}
        </p>
        <UnorderedList list={[t.legal78]} className="mt-8 ul-terms-spacing" />
        <OrderedList
          className="mt-8 ul-terms-spacing-50"
          list={[t.legal79, t.legal80]}
        />

        <p className="mt-16 body-small weight-600 text-uppercase">
          {t.legal81}
        </p>
        <UnorderedList list={[t.legal82]} className="mt-8 ul-terms-spacing" />
        <OrderedList
          className="ul-terms-spacing-50"
          list={[t.legal83, t.legal84]}
        />
        <UnorderedList
          list={[t.legal85, t.legal86]}
          className="mt-8 ul-terms-spacing"
        />
        <OrderedList
          className="ul-terms-spacing-50"
          list={[t.legal87, t.legal88]}
        />
        <UnorderedList
          list={[t.legal89, t.legal90]}
          className="mt-8 ul-terms-spacing-70"
        />
        <UnorderedList list={[t.legal91]} className="mt-8 ul-terms-spacing" />
        <OrderedList list={[t.legal92]} className="mt-8 ul-terms-spacing" />
        <UnorderedList list={[t.legal93]} className="mt-8 ul-terms-spacing" />
        <OrderedList list={[t.legal94]} className="mt-8 ul-terms-spacing" />

        <h4 className="mt-28 text-uppercase">{t.legal95}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal96 }}
        ></p>

        <h4 className="mt-28 text-uppercase">{t.legal97}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal98 }}
        ></p>
        <UnorderedList
          list={[t.legal99]}
          className="mt-8 weight-600 ul-terms-spacing"
        />
        <UnorderedList
          list={[t.legal100, t.legal101]}
          className="mt-8 ul-terms-spacing-50"
        />
        <UnorderedList
          list={[t.legal102]}
          className="mt-8 weight-600 ul-terms-spacing"
        />
        <UnorderedList
          list={[t.legal103, t.legal104]}
          className="mt-8 ul-terms-spacing-50"
        />

        <h4 className="mt-28 text-uppercase">{t.legal105}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal106 }}
        ></p>

        <h4 className="mt-28 text-uppercase">{t.legal107}</h4>
        <p
          className="mt-16 body-small"
          dangerouslySetInnerHTML={{ __html: t.legal108 }}
        ></p>
      </div>
    </div>
  );
};
