import { useEffect, useState } from "react";

export const useUpdateFavicon = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    () => window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = () => setIsDarkMode(mediaQuery.matches);

    mediaQuery.addListener(handleChange);

    return () => mediaQuery.removeListener(handleChange);
  }, []);

  const updateFavicon = () => {
    const favicon = document.getElementById("favicon");

    if (favicon) {
      favicon.href = isDarkMode ? "/favicon-dark.ico" : "/favicon-light.ico";
    }
  };

  useEffect(() => {
    updateFavicon();
  }, [isDarkMode]);

  return [isDarkMode, setIsDarkMode];
};
