const slackEmojis = [
  ":grinning:",
  ":smiley:",
  ":wink:",
  ":heart_eyes:",
  ":sunglasses:",
  ":thinking_face:",
  ":partying_face:",
  ":star-struck:",
  ":exploding_head:",
  ":new_moon_with_face:",
];

export const getRandomEmoji = () => {
  const randomIndex = Math.floor(Math.random() * slackEmojis.length);

  return slackEmojis[randomIndex];
};
