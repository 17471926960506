export const Radio = ({
  name,
  value,
  checked,
  onChange,
  label,
  labelClassName,
}) => {
  return (
    <label className={`form-charity-radio ${labelClassName}`}>
      <div className={`dot ${checked ? "checked" : ""}`}></div>
      <input
        type="radio"
        className="hidden"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="body-small text-secondary">{label}</span>
    </label>
  );
};
