import { useEffect } from "react";

import { fallbackLanguage } from "../constants/fallbackLanguage";

export const useHtmlLangAttribute = () => {
  useEffect(() => {
    const locale = process.env.REACT_APP_PUBLIC_LOCALE;

    if (!locale) {
      console.error("Locale is not defined in REACT_APP_PUBLIC_LOCALE");

      return;
    }

    const supportedLocales = ["en", "uk", "de", "eu"];

    if (!supportedLocales.includes(locale)) {
      console.error(`Unsupported locale: ${locale || fallbackLanguage}`);

      return;
    }

    if (locale) {
      document.documentElement.setAttribute("lang", locale);
    } else {
      document.documentElement.setAttribute("lang", fallbackLanguage);
    }

    return () => {
      document.documentElement.removeAttribute("lang");
    };
  }, []);
};
