import { useEffect, useState } from "react";

import { LocalizedImage } from "../LocalizedImage";
import { useIntersecting } from "../../hooks/useIntersecting";

export const ProductSectionList = ({ list }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="section product-section">
      <div className="product-section-description">
        {list.map((item, index) => {
          const { intersectionRef, isIntersecting } = useIntersecting();

          useEffect(() => {
            if (isIntersecting) {
              setCurrentIndex(index);
            }
          }, [isIntersecting]);

          return (
            <div
              ref={(el) => {
                intersectionRef.current = el;
              }}
              key={`product-container-${index}`}
              className="product-section-description-item"
            >
              <div className="product-section-description-translate-item">
                <div className="product-chip">{item.stepTitle}</div>
                <h2 className="mt-20 text-secondary">{item.title}</h2>
                <p className="mt-12 body-medium text-tertiary">
                  {item.description}
                </p>
              </div>
              <div className="product-section-description-item-image">
                <LocalizedImage
                  key={`desktop-product-${index}`}
                  srcSet={item.srcSet}
                  className="product-section-image active"
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="product-images">
        {list.map((item, index) => (
          <LocalizedImage
            key={`mobile-product-${index}`}
            srcSet={item.srcSet}
            className={`product-section-image ${
              currentIndex === index ? "active" : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
};
