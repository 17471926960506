import { createContext, useState, useCallback } from "react";

import { ReactComponent as CheckIcon } from "../../assets/check.svg";

export const ALERT_TYPES = {
  SUCCESS: "success",
  // ERROR: "error",
  // WARNING: "warning",
  // INFO: "info",
};

export const ALERT_ICONS = {
  [ALERT_TYPES.SUCCESS]: CheckIcon,
};

const GlobalAlert = ({ type, message, className }) => {
  const Icon = ALERT_ICONS[type || ALERT_TYPES.SUCCESS];

  return (
    <div className={`alert ${className}`}>
      <Icon className="text-green stroke-white" />
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
    </div>
  );
};

export const AlertContext = createContext(null);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    show: false,
    type: ALERT_TYPES.SUCCESS,
    message: "",
    duration: 3000,
  });

  const showAlert = useCallback(
    (message, type = ALERT_TYPES.SUCCESS, duration = 3000) => {
      setAlert({ show: true, type, message, duration });

      if (duration > 0) {
        setTimeout(() => {
          setAlert((prev) => ({ ...prev, show: false }));
        }, duration);
      }
    },
    []
  );

  const hideAlert = useCallback(() => {
    setAlert((prev) => ({ ...prev, show: false }));
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert, ALERT_TYPES }}>
      {children}
      <GlobalAlert
        type={alert.type || ALERT_TYPES.SUCCESS}
        message={alert.message || "message"}
        onClose={hideAlert}
        className={alert.show ? "visible" : ""}
      />
    </AlertContext.Provider>
  );
};
