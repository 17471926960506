import { useCallback, useRef, useState } from "react";

export const useCollapse = () => {
  const ref = useRef();
  const [collapsed, setCollapsed] = useState({ value: false, height: "0px" });

  const toggle = useCallback(() => {
    setCollapsed((prevState) => ({
      value: !prevState.value,
      height: `${!prevState.value ? ref.current.scrollHeight : 0}px`,
    }));
  }, [collapsed.value]);

  return { collapsed, ref, toggle };
};
