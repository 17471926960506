import { useEffect } from "react";

export const useAnalyticsScript = () => {
  useEffect(() => {
    let dataDomain = "";

    if (process.env.REACT_APP_PUBLIC_LOCALE === "en") {
      dataDomain = "marmend.com";
    } else if (process.env.REACT_APP_PUBLIC_LOCALE === "uk") {
      dataDomain = "marmend.com.ua";
    } else if (process.env.REACT_APP_PUBLIC_LOCALE === "de") {
      dataDomain = "marmend.de";
    } else if (process.env.REACT_APP_PUBLIC_LOCALE === "eu") {
      dataDomain = "marmend.eu";
    } else {
      console.error("Unknown domain");

      return;
    }

    const script = document.createElement("script");
    script.src = "https://analytic.marmend.com/js/script.js";
    script.defer = true;
    script.setAttribute("data-domain", dataDomain);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};
