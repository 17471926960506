import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as ChevronDown } from "../assets/chevron-down.svg";

import { paths } from "../constants/paths";
import { login, tryForFree } from "../utils/getPath";
import { useTranslation } from "../hooks/useTranslation";

import { HeaderMenu } from "./HeaderMenu";
import { HeaderProducts } from "./HeaderProducts";
import { LanguageButton } from "./LanguageButton";
import { HamburgerButton } from "./buttons/HamburgerButton";

const isActive = (path, active) => (path === active ? "active" : "");

export const Header = ({ active }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuIsOpen] = useState(false);
  const [isExpandedProducts, setIsExpandedProducts] = useState(false);

  const toggleProducts = useCallback(() => {
    setIsExpandedProducts((prevState) => !prevState);

    if (isExpandedProducts) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isExpandedProducts]);

  const toggleMenu = useCallback(() => {
    setMenuIsOpen((prevState) => !prevState);

    if (isMenuOpen) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isMenuOpen]);

  const isHeaderExpanded = isExpandedProducts || isMenuOpen;

  return (
    <>
      <div className={`f ${isExpandedProducts ? "expanded" : ""}`}></div>
      <HeaderMenu
        open={isMenuOpen}
        toggleMenu={toggleMenu}
        isExpandedProducts={isExpandedProducts}
        setIsExpandedProducts={setIsExpandedProducts}
      />
      <header className={`header ${isHeaderExpanded ? "expanded" : ""}`}>
        <Link to={paths.home}>
          <Logo />
        </Link>

        <nav className="navigation">
          <Link
            to={paths.home}
            className={`button-navigation body-medium weight-500 ${isActive(
              paths.home,
              active
            )}`}
          >
            {t.home}
          </Link>
          <div
            className={`button-navigation ${
              isExpandedProducts ? "expanded pointer-events-none" : ""
            }`}
            onClick={toggleProducts}
          >
            <div className="body-medium weight-500">{t.product}</div>
            <ChevronDown className="dropdown-icon" />
          </div>
          <Link
            to={paths.prices}
            className={`button-navigation body-medium weight-500 ${isActive(
              paths.prices,
              active
            )}`}
          >
            {t.price}
          </Link>
          <Link
            to={paths.contacts}
            className={`button-navigation body-medium weight-500 ${isActive(
              paths.contacts,
              active
            )}`}
          >
            {t.contactUs}
          </Link>
        </nav>

        <HeaderProducts
          isExpandedProducts={isExpandedProducts}
          setIsExpandedProducts={setIsExpandedProducts}
        />

        <div className="header-buttons">
          <LanguageButton className={`desktop ${isMenuOpen ? "expanded" : ""}`} />

          <Link to={login} className="h-b">
            <button className="button-text body-medium weight-500 a">
              {t.login}
            </button>
          </Link>

          <Link to={tryForFree("Company")} className="h-b">
            <button className="button outlined-primary body-medium weight-500">
              {t.tryForFree}
            </button>
          </Link>

          <HamburgerButton isOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </div>
      </header>
    </>
  );
};
