import { useEffect } from "react";

const removeFocus = (button) => {
  setTimeout(() => button.blur(), 300);
};

export const useGlobalButtonFocus = () => {
  useEffect(() => {
    const handleClick = (event) => {
      const clickedElement = event.target;
      const button = clickedElement.closest("button");

      if (button) {
        removeFocus(button);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
};
