import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useResolvedPath } from "react-router-dom";

import { ReactComponent as Bolt } from "../assets/bolt.svg";
import { ReactComponent as Magnet } from "../assets/magnet.svg";
import { ReactComponent as UserGroup } from "../assets/user-group.svg";
import { ReactComponent as CheckSingle } from "../assets/check-single.svg";
import { ReactComponent as DocumentText } from "../assets/document-text.svg";

import { useTranslation } from "../hooks/useTranslation";
import { paths } from "../constants/paths";
import { useOutsideClick } from "../hooks/useOutsideClick";

export const HeaderProducts = ({
  isExpandedProducts,
  setIsExpandedProducts,
}) => {
  const { t } = useTranslation();
  const { pathname } = useResolvedPath();
  const [visible, setVisible] = useState(-1);

  const products = useMemo(
    () => [
      {
        id: 1,
        title: t.vacancies,
        icon: <DocumentText />,
        list: [t.v1, t.v2, t.v3, t.v4, t.v5],
        to: paths.vacancies,
      },
      {
        id: 2,
        title: t.atsDatabase,
        icon: <UserGroup />,
        list: [t.ats1, t.ats2, t.ats3, t.ats4, t.ats5, t.ats6],
        to: paths.atsDatabase,
      },
      {
        id: 3,
        title: t.recruitingAsService,
        icon: <Magnet />,
        list: [t.r1, t.r2, t.r3, t.r4],
        extra: t.hot,
        to: paths.recruitingAsService,
      },
      {
        id: 4,
        title: t.hrPlatform,
        icon: <Bolt />,
        list: [t.hrTitle1, t.hrTitle3, t.hrTitle4, t.hrTitle5],
        to: paths.hrPlatform,
      },
    ],
    [t]
  );

  const handleMouseEnter = useCallback((index) => {
    setVisible(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setVisible(-1);
  }, []);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
      setIsExpandedProducts(false);
    };
  }, [pathname]);

  const { ref } = useOutsideClick(() => {
    if (isExpandedProducts) {
      document.body.style.overflow = "";
      setIsExpandedProducts(false);
    }
  });

  return (
    <div
      ref={ref}
      className={`products-expanded-list ${
        isExpandedProducts ? "expanded" : ""
      }`}
    >
      <div
        className={`products-expanded-list-description-container default-list ${
          visible === -1 ? "" : "opacity-0"
        }`}
      >
        <DocumentText />
        <UserGroup />
        <Magnet />
        <Bolt />
      </div>

      {products.map((product, index) => (
        <Fragment key={product.title || `product-header-${index}`}>
          {index === 0 && (
            <p className="body-medium text-secondary weight-500 mb-4 pl-12">
              {t.recruiting}
            </p>
          )}
          {index === 3 && (
            <p className="body-medium text-secondary weight-500 mt-16 mb-4 pl-12">
              {t.hrManagement}
            </p>
          )}
          <Link to={product.to}>
            <div
              className="products-expanded-button"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="products-expanded-button-icon text-primary">
                {product.icon}
              </div>
              <p>{product.title}</p>
              {product?.extra && (
                <span className="products-expanded-extra">{product.extra}</span>
              )}
            </div>
          </Link>

          <div className="products-expanded-list-description-container">
            <ul
              className={`products-expanded-list-description ${
                index === visible ? "visible" : ""
              }`}
            >
              {product.list.map((item, idx) => (
                <li
                  key={
                    item ||
                    `product-benefits-${idx} ${
                      product.id === visible ? "" : "opacity-0"
                    }`
                  }
                >
                  <CheckSingle className="icon-16 text-primary" />
                  {item}
                </li>
              ))}

              {product.icon}
            </ul>
          </div>
        </Fragment>
      ))}
    </div>
  );
};
