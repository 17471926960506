import { ReactComponent as Task1En } from "../assets/images/en/task-1.svg";
import { ReactComponent as Task2En } from "../assets/images/en/task-2.svg";
import { ReactComponent as Task3En } from "../assets/images/en/task-3.svg";
import { ReactComponent as Task4En } from "../assets/images/en/task-4.svg";
import { ReactComponent as Task5En } from "../assets/images/en/task-5.svg";
import { ReactComponent as Task6En } from "../assets/images/en/task-6.svg";
import { ReactComponent as Task7En } from "../assets/images/en/task-7.svg";

import { ReactComponent as Task1Uk } from "../assets/images/uk/task-1.svg";
import { ReactComponent as Task2Uk } from "../assets/images/uk/task-2.svg";
import { ReactComponent as Task3Uk } from "../assets/images/uk/task-3.svg";
import { ReactComponent as Task4Uk } from "../assets/images/uk/task-4.svg";
import { ReactComponent as Task5Uk } from "../assets/images/uk/task-5.svg";
import { ReactComponent as Task6Uk } from "../assets/images/uk/task-6.svg";
import { ReactComponent as Task7Uk } from "../assets/images/uk/task-7.svg";

import { ReactComponent as Task1De } from "../assets/images/de/task-1.svg";
import { ReactComponent as Task2De } from "../assets/images/de/task-2.svg";
import { ReactComponent as Task3De } from "../assets/images/de/task-3.svg";
import { ReactComponent as Task4De } from "../assets/images/de/task-4.svg";
import { ReactComponent as Task5De } from "../assets/images/de/task-5.svg";
import { ReactComponent as Task6De } from "../assets/images/de/task-6.svg";
import { ReactComponent as Task7De } from "../assets/images/de/task-7.svg";

const tasks = {
  en: [<Task1En />, <Task2En />, <Task3En />, <Task4En />, <Task5En />, <Task6En />, <Task7En />],
  eu: [<Task1En />, <Task2En />, <Task3En />, <Task4En />, <Task5En />, <Task6En />, <Task7En />],
  uk: [<Task1Uk />, <Task2Uk />, <Task3Uk />, <Task4Uk />, <Task5Uk />, <Task6Uk />, <Task7Uk />],
  de: [<Task1De />, <Task2De />, <Task3De />, <Task4De />, <Task5De />, <Task6De />, <Task7De />],
};

const currentLocale = process.env.REACT_APP_PUBLIC_LOCALE || "en";

export const useTasks = () => {
  return { tasksList: tasks[currentLocale], currentLocale };
};
