import { useCallback, useState } from "react";

import { Text } from "./form/Text";
import { TextArea } from "./form/Textarea";
import { ContactPerson } from "./ContactPerson";
import { LocalizedImage } from "./LocalizedImage";

import { useAlert } from "../hooks/useAlert";
import { useTranslation } from "../hooks/useTranslation";
import { getRandomEmoji } from "../utils/getRandomEmoji";
import { useValidateField } from "../hooks/useValidateField";

const host = window.location.host || window.location.hostname;

export const Contact = () => {
  const { t } = useTranslation();
  const { validateField } = useValidateField();
  const { showAlert, ALERT_TYPES } = useAlert();
  const [formState, setFormState] = useState({
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    theme: { value: "", error: "" },
    request: { value: "", error: "" },
  });

  const handleChange = useCallback(
    (field, value) => {
      setFormState((prevState) => ({
        ...prevState,
        [field]: { value: value },
      }));
    },
    [t]
  );

  const resetForm = () => {
    setFormState({
      name: { value: "", error: "" },
      email: { value: "", error: "" },
      theme: { value: "", error: "" },
      request: { value: "", error: "" },
    });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newFormState = { ...formState };

      let isValid = true;

      Object.keys(newFormState).forEach((key) => {
        if (key === "request") {
          return;
        }

        const error = validateField(key, newFormState[key].value);

        newFormState[key].error = error;

        if (error) {
          isValid = false;
        }
      });

      if (isValid) {
        try {
          const icon_emoji = getRandomEmoji();
          const formData = Object.keys(newFormState).reduce((acc, key) => {
            acc[key] = newFormState[key].value;

            return acc;
          }, {});
          const request =
            formData.request.length > 0 ? `\nRequest: ${formData.request}` : "";
          const text = `Name: ${formData.name}\nHost: ${host}\nEmail: ${formData.email}\nTheme: ${formData.theme}${request}`;
          const username = "Form: Contact us";
          const data = {
            text,
            username,
            icon_emoji,
            channel: "#site_inbox",
          };
          await fetch(
            "https://hooks.slack.com/services/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",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              mode: "no-cors",
              body: JSON.stringify(data),
            }
          );

          resetForm();
          showAlert(t.successfullySubmitted, ALERT_TYPES.SUCCESS);
        } catch (error) {
          console.error("Error:", error);
          setFormState(newFormState);
        }
      } else {
        setFormState(newFormState);
      }
    },
    [formState, t, host]
  );

  return (
    <div className="section">
      <div className="contact-us-content">
        <div className="contact-form-about">
          <div>
            <h2 className="text-secondary">{t.wantAsk}</h2>
            <p className="mt-12 body-medium text-tertiary">
              {t.writeToAddress}
            </p>
            <p className="mt-4 text-primary body-medium weight-600">
              <a
                href="mailto:company@marmend.com"
                target="_blank"
                className="a"
              >
                company@marmend.com
              </a>
            </p>
            <ContactPerson className="mt-60" />
          </div>

          <LocalizedImage
            srcSet={{ default: "contact-help-desktop.png", sm: "contact-help-mobile.png" }}
            className="contact-f-image"
          />
        </div>

        <div className="contact-form-card shadow-8 relative">
          <h4 className="text-secondary">{t.contactWithUs}</h4>

          <div className="blur-ball blur-size-460 blur-bg-2 blur-filter-90 left-bottom"></div>

          <form noValidate onSubmit={handleSubmit} className="mt-28">
            <Text
              required
              type="text"
              name="name"
              label={t.nameLabel}
              placeholder={t.namePlaceholder}
              onChange={handleChange}
              value={formState.name.value}
              error={formState.name.error}
            />
            <Text
              required
              type="email"
              name="email"
              label={t.emailLabel}
              placeholder={t.emailPlaceholderEnter}
              containerClassName="mt-12"
              onChange={handleChange}
              value={formState.email.value}
              error={formState.email.error}
            />
            <Text
              required
              type="email"
              name="theme"
              label={t.themeLabel}
              placeholder={t.themePlaceholder}
              containerClassName="mt-12"
              onChange={handleChange}
              value={formState.theme.value}
              error={formState.theme.error}
            />
            <TextArea
              type="textarea"
              name="request"
              label={t.yourRequestLabel}
              placeholder={t.yourRequestPlaceholder}
              containerClassName="mt-12"
              onChange={handleChange}
              value={formState.request.value}
              error={formState.request.error}
            />
            <button
              type="submit"
              className="button contained-primary mt-28 w-full"
            >
              {t.sendMessage}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
