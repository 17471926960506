import { useEffect, useRef, useState } from "react";

export const useIntersecting = (threshold = 1) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const intersectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef.current);
      }
    };
  }, []);

  return { isIntersecting, setIsIntersecting, intersectionRef };
};
