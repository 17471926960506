import { useState, useRef, useEffect, useCallback } from "react";

export const Marquee = ({
  children,
  speed = 50,
  direction = "left",
  pauseOnHover = true,
}) => {
  const [isPaused, setIsPaused] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const animationRef = useRef(null);
  const startXRef = useRef(null);
  const currentXRef = useRef(0);
  const speedRef = useRef(speed);
  const directionMultiplierRef = useRef(direction === "left" ? 1 : -1);

  const animate = useCallback(() => {
    if (!isPaused) {
      currentXRef.current +=
        (speedRef.current / 60) * directionMultiplierRef.current;

      if (Math.abs(currentXRef.current) >= contentRef.current.offsetWidth) {
        currentXRef.current = 0;
      }
      containerRef.current.style.transform = `translateX(${-currentXRef.current}px)`;
    }

    animationRef.current = requestAnimationFrame(animate);
  }, [isPaused]);

  useEffect(() => {
    const content = contentRef.current;
    const clone = content.cloneNode(true);

    containerRef.current.appendChild(clone);
    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [animate]);

  const handleInteractionStart = useCallback((e) => {
    setIsPaused(true);
    startXRef.current = e.type.includes("mouse") ? e.pageX : e.touches[0].pageX;
  }, []);

  const handleInteractionMove = useCallback((e) => {
    if (startXRef.current === null) return;

    const currentX = e.type.includes("mouse") ? e.pageX : e.touches[0].pageX;
    const diff =
      (startXRef.current - currentX) * directionMultiplierRef.current;

    currentXRef.current += diff;
    containerRef.current.style.transform = `translateX(${-currentXRef.current}px)`;
    startXRef.current = currentX;
  }, []);

  const handleInteractionEnd = useCallback(() => {
    startXRef.current = null;
    setTimeout(() => setIsPaused(false), 1000);
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (pauseOnHover) setIsPaused(true);
  }, [pauseOnHover]);

  const handleMouseLeave = useCallback(() => {
    if (pauseOnHover) setIsPaused(false);
  }, [pauseOnHover]);

  return (
    <div className="marquee-container" style={{ overflow: "hidden" }}>
      <div
        ref={containerRef}
        className="marquee-content"
        style={{
          display: "flex",
          flexDirection: direction === "left" ? "row" : "row-reverse",
          cursor: "grab",
          userSelect: "none",
        }}
        onMouseDown={handleInteractionStart}
        onTouchStart={handleInteractionStart}
        onMouseMove={handleInteractionMove}
        onTouchMove={handleInteractionMove}
        onMouseUp={handleInteractionEnd}
        onTouchEnd={handleInteractionEnd}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div ref={contentRef} className="flex pb-20">
          {children}
        </div>
        <div aria-hidden="true" className="flex pb-20">
          {children}
        </div>
      </div>
    </div>
  );
};
