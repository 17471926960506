import { useMemo } from "react";

import { LocalizedImage } from "./LocalizedImage";

import { useTranslation } from "../hooks/useTranslation";

export const ContactPerson = ({ className }) => {
  const { t, currentLocale } = useTranslation();

  const personMap = useMemo(
    () => ({
      en: {
        image: "avatar-ceo.jpg",
        fullName: t.CEOFullName,
        position: t.CEO,
      },
      eu: {
        image: "avatar-ceo.jpg",
        fullName: t.CEOFullName,
        position: t.CEO,
      },
      de: {
        image: "avatar-cpo.jpg",
        fullName: t.CPOFullName,
        position: t.CPO,
      },
      uk: {
        image: "avatar-cpo.jpg",
        fullName: t.CPOFullName,
        position: t.CPO,
      },
    }),
    [t]
  );

  return (
    <>
      <div className={`flex items-center gap-12 ${className}`}>
        <LocalizedImage
          srcSet={{ default: personMap[currentLocale].image }}
          className="icon-50 rounded-12"
        />

        <div>
          <p className="body-medium text-secondary weight-500">
            {personMap[currentLocale].fullName}
          </p>
          <p className="body-small text-primary">
            {personMap[currentLocale].position}
          </p>
        </div>
      </div>
    </>
  );
};
