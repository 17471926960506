import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { paths } from "../constants/paths";
import { useTranslation } from "./useTranslation";

export const useDocumentTitle = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const company = "Marmend";
    let title = company;

    switch (path) {
      case paths.home:
        title = t.home;
        break;

      case paths.prices:
        title = t.prices;
        break;

      case paths.aboutUs:
        title = t.aboutUs;
        break;

      case paths.contacts:
        title = t.contacts;
        break;

      case paths.terms:
        title = t.termsOfService;
        break;

      case paths.termsCandidate:
        title = t.termsCandidateTitle;
        break;

      case paths.cookies:
        title = t.cookies;
        break;

      case paths.impressum:
        title = "Impressum";
        break;

      case paths.vacancies:
        title = t.vacancies;
        break;

      case paths.privacy:
        title = t.privacyPolicy;
        break;

      case paths.hrPlatform:
        title = t.hrPlatform;
        break;

      case paths.atsDatabase:
        title = t.atsDatabase;
        break;

      case paths.recruitingAsService:
        title = t.recruitingAsService;
        break;

      case paths.integrationSlack:
        title = t.slackIntegration;
        break;

      case paths.integrationGoogleCalendar:
        title = t.googleCalendarIntegration;
        break;

      default:
        title = company;
    }

    if (title === "undefined" || title === "null") {
      document.title = company;
    } else {
      document.title = `${title} • ${company}`;
    }
  }, [location, t]);
};
