import { useCallback, useEffect, useState } from "react";

export const useBackgroundChanger = (cardsCount) => {
  const [activeIndices, setActiveIndices] = useState({
    list1: null,
    list2: null,
  });
  const [availableIndices, setAvailableIndices] = useState({
    list1: Array.from({ length: cardsCount }, (_, i) => i),
    list2: Array.from({ length: cardsCount }, (_, i) => i),
  });

  const getRandomIndex = useCallback((array) => {
    return Math.floor(Math.random() * array.length);
  }, []);

  const updateBackgrounds = useCallback(() => {
    setActiveIndices((prev) => {
      const newActiveIndices = { list1: null, list2: null };
      ["list1", "list2"].forEach((listName) => {
        if (availableIndices[listName].length === 0) {
          setAvailableIndices((prev) => ({
            ...prev,
            [listName]: Array.from({ length: cardsCount }, (_, i) => i),
          }));
        }
        const randomIndex = getRandomIndex(availableIndices[listName]);
        newActiveIndices[listName] = availableIndices[listName][randomIndex];
        setAvailableIndices((prev) => ({
          ...prev,
          [listName]: prev[listName].filter(
            (i) => i !== newActiveIndices[listName]
          ),
        }));
      });
      return newActiveIndices;
    });
  }, [availableIndices, cardsCount, getRandomIndex]);

  useEffect(() => {
    const intervalId = setInterval(updateBackgrounds, 2000);
    return () => clearInterval(intervalId);
  }, [updateBackgrounds]);

  return activeIndices;
};
