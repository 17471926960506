import { useMemo } from "react";

import goal1 from "../assets/goal-1.svg";
import goal2 from "../assets/goal-2.svg";
import goal3 from "../assets/goal-3.svg";
import goal4 from "../assets/goal-4.svg";
import goal5 from "../assets/goal-5.svg";
import goal6 from "../assets/goal-6.svg";

import { Marquee } from "./Marquee";

import { useMediaQuery } from "../hooks/useMediaQuery";
import { useTranslation } from "../hooks/useTranslation";

export const Targets = () => {
  const { t } = useTranslation();

  const targetsList = useMemo(
    () => [
      { title: t.target1Title, subtitle: t.target1Subtitle, image: goal1 },
      { title: t.target2Title, subtitle: t.target2Subtitle, image: goal2 },
      { title: t.target3Title, subtitle: t.target3Subtitle, image: goal3 },
      { title: t.target4Title, subtitle: t.target4Subtitle, image: goal4 },
      { title: t.target5Title, subtitle: t.target5Subtitle, image: goal5 },
      { title: t.target6Title, subtitle: t.target6Subtitle, image: goal6 },
    ],
    [t]
  );

  const isLargeScreen = useMediaQuery("(min-width: 1200px)");

  return (
    <>
      <h2
        className="text-center text-secondary weight-300 relative z-2"
        dangerouslySetInnerHTML={{ __html: t.targetHead }}
      ></h2>
      {isLargeScreen ? (
        <div className="section-target-list">
          {targetsList.map((item, index) => (
            <div
              key={item.title || `target-${index}`}
              className="section-target-card shadow-7"
            >
              <div>
                <img
                  src={item.image}
                  alt="image"
                  className="section-target-image"
                />
              </div>
              <div>
                <p className="text-secondary body-medium weight-600">
                  {item.title}
                </p>
                <p className="text-tertiary body-medium mt-16">
                  {item.subtitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-20 after-gradient-right-to-left">
          <Marquee pauseOnHover speed={20} direction="left">
            {targetsList.map((item, index) => (
              <div
                id={`target-${index}-${item.image}`}
                key={`target-${index}-${item.image}`}
                className="section-target-card shadow-7"
              >
                <img src={item.image} alt="" className="section-target-image" />

                <div>
                  <p className="text-secondary body-medium weight-600">
                    {item.title}
                  </p>
                  <p className="text-tertiary body-medium mt-16">
                    {item.subtitle}
                  </p>
                </div>
              </div>
            ))}
          </Marquee>
        </div>
      )}
    </>
  );
};
