import { useMemo } from "react";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";

import { InnovateCard } from "./InnovateCard";

export const Innovate = () => {
  const { t } = useTranslation();

  const list = useMemo(
    () => [
      {
        title: t.innovation1Title,
        description: t.innovation1Description,
        to: paths.vacancies,
        srcSet: {
          default: "innovation-1-desktop.jpg",
          md: "innovation-1-tablet.jpg",
          sm: "innovation-1-mobile.jpg",
        },
        buttonTitle: t.innovation1ButtonText,
      },
      {
        title: t.innovation2Title,
        description: t.innovation2Description,
        to: paths.atsDatabase,
        srcSet: {
          default: "innovation-2-desktop.jpg",
          md: "innovation-2-tablet.jpg",
          sm: "innovation-2-mobile.jpg",
        },
        buttonTitle: t.innovation2ButtonText,
      },
      {
        title: t.innovation3Title,
        description: t.innovation3Description,
        to: paths.hrPlatform,
        srcSet: {
          default: "innovation-3-desktop.jpg",
          md: "innovation-3-tablet.jpg",
          sm: "innovation-3-mobile.jpg",
        },
        buttonTitle: t.innovation3ButtonText,
      },
      {
        title: t.innovation4Title,
        description: t.innovation4Description,
        to: paths.hrPlatform,
        srcSet: {
          default: "innovation-4-desktop.jpg",
          md: "innovation-4-tablet.jpg",
          sm: "innovation-4-mobile.jpg",
        },
        buttonTitle: t.innovation4ButtonText,
      },
    ],
    [t]
  );

  return (
    <div className="section">
      <div className="flex justify-center">
        <h2
          className="text-secondary text-center weight-300 max-w-700"
          dangerouslySetInnerHTML={{ __html: t.innovateHead }}
        />
      </div>
      <div className="section-innovate-list">
        {list.map((item, index) => (
          <InnovateCard key={item.title || index} item={item} />
        ))}
      </div>
    </div>
  );
};
