import { useState, useEffect, useCallback, useMemo } from "react";

import { useTranslation } from "../../hooks/useTranslation";

import { Ticker } from "../Ticker";
import { LocalizedImage } from "../LocalizedImage";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const MarqueeCard = ({ imageStates, item, index }) => {
  return (
    <div
      className={`moving-image ${imageStates[index]}`}
      style={{ ...positions[imageStates[index]] }}
    >
      <LocalizedImage srcSet={{ default: item.image }} className="" />

      <div className="description">
        <p className="body-medium weight-500 text-secondary">{item.name}</p>
        <p className="body-small text-primary">{item.position}</p>
        <p className="mt-8 body-small text-tertiary">{item.location}</p>
      </div>
    </div>
  );
};

const TickerCard = ({ item }) => {
  return (
    <div className="moving-image">
      <LocalizedImage srcSet={{ default: item.image }} className="" />

      <div className="description">
        <p className="body-medium weight-500 text-secondary">{item.name}</p>
        <p className="body-small text-primary">{item.position}</p>
        <p className="mt-8 body-small text-tertiary">{item.location}</p>
      </div>
    </div>
  );
};

const positions = {
  default: { right: "-210px", top: "50px" },
  right: { right: "60px", top: "50px" },
  current: { right: "330px", top: "50px" },
  top: { right: "330px", top: "-100px" },
};

export const MarqueeAnimation = () => {
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery("(min-width: 1200px)");

  const images = useMemo(
    () => [
      {
        image: "service-avatar-1.jpg",
        position: t.service1Position,
        name: t.service1Name,
        location: t.service1Location,
      },
      {
        image: "service-avatar-2.jpg",
        position: t.service2Position,
        name: t.service2Name,
        location: t.service2Location,
      },
      {
        image: "service-avatar-3.jpg",
        position: t.service3Position,
        name: t.service3Name,
        location: t.service3Location,
      },
      {
        image: "service-avatar-4.jpg",
        position: t.service4Position,
        name: t.service4Name,
        location: t.service4Location,
      },
      {
        image: "service-avatar-5.jpg",
        position: t.service5Position,
        name: t.service5Name,
        location: t.service5Location,
      },
    ],
    [t]
  );

  const [imageStates, setImageStates] = useState(
    images.map((_, index) => {
      if (index === 0) {
        return "current";
      }

      if (index === 1) {
        return "right";
      }

      if (index === 2) {
        return "default";
      }

      return "top";
    })
  );

  const moveImages = useCallback(() => {
    setImageStates((prevStates) => {
      const newStates = [...prevStates];
      const defaultIndex = newStates.findIndex((state) => state === "default");
      const rightIndex = newStates.findIndex((state) => state === "right");
      const currentIndex = newStates.findIndex((state) => state === "current");

      if (currentIndex !== -1) {
        newStates[currentIndex] = "top";
      }

      if (rightIndex !== -1) {
        newStates[rightIndex] = "current";
      }

      if (defaultIndex !== -1) {
        newStates[defaultIndex] = "right";
      }

      const nextIndex = (defaultIndex + 1) % images.length;

      newStates[nextIndex] = "default";

      return newStates;
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(moveImages, 2000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="product-images-container">
      {isLargeScreen ? (
        <div className="marquee-box">
          {images.map((item, index) => (
            <MarqueeCard
              key={item.image || `marquee-${index}`}
              index={index}
              item={item}
              imageStates={imageStates}
            />
          ))}
        </div>
      ) : (
        <div className="ticker-box-service">
          <Ticker title="service" direction="right">
            {images.map((item, index) => (
              <TickerCard key={item.image || `ticker-${index}`} item={item} />
            ))}
          </Ticker>
        </div>
      )}
    </div>
  );
};
