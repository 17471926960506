import { Fragment, useMemo } from "react";

import { useTranslation } from "../hooks/useTranslation";

import { UnorderedList } from "./ListUnordered";

import "../legal.css";

export const PageLegalTermsOfServiceCandidate = () => {
  const { t } = useTranslation();

  const sections = useMemo(
    () => [
      {
        title: t.termsCandidate1,
        unorderedList: [
          { title: t.termsCandidate2 },
          { title: t.termsCandidate3 },
        ],
      },
      {
        title: t.termsCandidate4,
        subsections: [
          { title: t.termsCandidate5 },
          { title: t.termsCandidate6 },
          { title: t.termsCandidate7 },
          { title: t.termsCandidate8 },
          { title: t.termsCandidate9 },
        ],
      },
      {
        title: t.termsCandidate10,
        unorderedList: [
          { title: t.termsCandidate11 },
          {
            title: t.termsCandidate12,
            bulletList: [
              t.termsCandidate13,
              t.termsCandidate14,
              t.termsCandidate15,
            ],
          },
        ],
      },
      {
        title: t.termsCandidate16,
        subsections: [{ title: t.termsCandidate17 }],
        unorderedList2: [
          { title: t.termsCandidate18 },
          { title: t.termsCandidate19 },
        ],
      },
      {
        title: t.termsCandidate20,
        subsections: [{ title: t.termsCandidate21 }],
        unorderedSublist2: [
          {
            title: t.termsCandidate22,
            list: [t.termsCandidate23, t.termsCandidate24],
          },
          {
            title: t.termsCandidate25,
            list: [t.termsCandidate26],
          },
        ],
      },
      {
        title: t.termsCandidate27,
        subsections: [
          {
            title: t.termsCandidate28,
            unorderedSublist2: [t.termsCandidate29, t.termsCandidate30],
          },
          {
            title: t.termsCandidate31,
            unorderedSublist2: [t.termsCandidate32],
          },
          { title: t.termsCandidate33 },
        ],
      },
      {
        title: t.termsCandidate34,
        subsections: [
          {
            title: t.termsCandidate35,
            bulletList: [
              t.termsCandidate36,
              t.termsCandidate37,
              t.termsCandidate38,
            ],
          },
        ],
      },
      {
        title: t.termsCandidate39,
        subsections: [{ title: t.termsCandidate40 }],
      },
      {
        title: t.termsCandidate41,
        subsections: [
          {
            title: t.termsCandidate42,
            bulletList: [t.termsCandidate43, t.termsCandidate44],
          },
        ],
      },
      {
        title: t.termsCandidate45,
        subsections: [
          { title: t.termsCandidate46 },
          { title: t.termsCandidate47 },
        ],
      },
      {
        title: t.termsCandidate48,
        subsections: [{ title: t.termsCandidate49 }],
      },
      {
        title: t.termsCandidate50,
        subsections: [
          { title: t.termsCandidate51 },
          { title: t.termsCandidate52 },
        ],
      },
    ],
    [t]
  );

  return (
    <div className="section section-terms text-secondary">
      <h1 className="text-secondary text-uppercase">{t.termsCandidateTitle}</h1>

      <ol
        style={{
          counterReset: "section",
          listStyleType: "none",
          padding: 0,
        }}
      >
        {sections.map((section, sectionIndex) => (
          <li key={sectionIndex}>
            <h4
              className="weight-600 text-uppercase mt-28"
              style={{
                counterIncrement: "section",
                marginRight: "5px",
              }}
            >
              {sectionIndex + 1}. {section.title}
            </h4>

            {section?.unorderedList && (
              <ul style={{ listStyleType: "none" }} className="mt-16">
                {section.unorderedList.map((sub, i2) => (
                  <Fragment key={sub.title || i2}>
                    <li
                      key={sub.title}
                      className="body-small mt-8"
                      dangerouslySetInnerHTML={{ __html: sub.title }}
                    />

                    {sub.bulletList && (
                      <UnorderedList
                        list={sub.bulletList}
                        className="ml-16 mt-8"
                      />
                    )}
                  </Fragment>
                ))}
              </ul>
            )}

            {section?.subsections && (
              <ol style={{ counterReset: "subsection" }}>
                {section.subsections.map((sub, i3) => (
                  <Fragment key={i3}>
                    <li
                      key={i3}
                      className="body-small mt-4"
                      style={{
                        counterIncrement: "subsection",
                        listStyleType: "none",
                      }}
                    >
                      {sub.title}
                    </li>

                    {sub?.bulletList && (
                      <UnorderedList
                        list={sub.bulletList}
                        className="ml-16 mt-8"
                      />
                    )}
                  </Fragment>
                ))}
              </ol>
            )}

            {section?.unorderedList2 && (
              <ul style={{ listStyleType: "none" }} className="mt-8">
                {section.unorderedList2.map((sub, i4) => (
                  <Fragment key={sub.title || i4}>
                    <li
                      key={sub.title}
                      className="body-small mt-8"
                      dangerouslySetInnerHTML={{ __html: sub.title }}
                    />
                    {sub.list && (
                      <UnorderedList list={sub.list} className="ml-16 mt-8" />
                    )}
                    {sub.bulletList && (
                      <UnorderedList
                        list={sub.bulletList}
                        className="ml-16 mt-8"
                      />
                    )}
                  </Fragment>
                ))}
              </ul>
            )}

            {section?.unorderedSublist2 && (
              <ul style={{ listStyleType: "none" }} className="mt-8">
                {section.unorderedSublist2.map((sub, i5) => (
                  <Fragment key={sub.title || i5}>
                    <li
                      key={sub.title}
                      className="body-small text-uppercase weight-600 mt-8"
                      dangerouslySetInnerHTML={{ __html: sub.title }}
                    />
                    {sub.list && (
                      <UnorderedList list={sub.list} className="ml-16 mt-8" />
                    )}
                  </Fragment>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
